
var myExtObject = (
    
    function() {
    return {        
      func1: function(e) {
        var o = {};
        var ppp = { 
            100: function(e, o, t) {
                "use strict";
                function n(e, o) {
                    var t = document.createElement("link");
                    t.rel = "stylesheet",
                    t.type = "text/css",
                    t.id = e,
                    t.href = o,
                    t.addEventListener("load", (function() {
                        for (var o = document.querySelectorAll("link[id=" + e + "]"), t = 0; t < o.length - 1; t++)
                            o[t].parentNode.removeChild(o[t])
                    }
                    )),
                    document.head.appendChild(t)
                }
                t.d(o, "a", (function() {
                    return n
                }
                ))
            },
            307:function(e, o, t) {
                console.log(e)
             e.exports = t(308)
            },
            308 : function(e, o, t) {
                "use strict";
                t.r(o);
                var n = t(100)
                  , r = "5625a6da1e7eab9180fbdf69911620545ab83fd4"
                  , d = function(e, o) {
                    var t = document.createElement("div");
                    t.style.cssText = "color:white;background-color:red;padding:5em;font-weight:bold;text-align:center;",
                    t.innerHTML = e + '<br/><b>More information can be found at<br/><br/><a href="https://help.spreadshop.com/hc/en-us/articles/360010529039">https://help.spreadshop.com/hc/en-us/articles/360010529039</a></b>',
                    o ? o.parentNode.insertBefore(t, o) : document.body.appendChild(t)
                };
                function i(e) {
                    !function(e) {
                        if (!e || "object" != typeof e) {
                            var o = "Could not embed SpreadShop: No 'spread_shop_config' object found in window";
                            throw d(o),
                            new Error(o)
                        }
                        if (["baseId", "prefix", "shopName"].forEach((function(o) {
                            if (!e[o])
                                throw d("<h2>Could not embed your SpreadShop!</h2><p>Please provide a '" + o + "' in the spread_shop_config object.</p>"),
                                new Error("Could not embed SpreadShop: Element with id '" + e.baseId + "' not found")
                        }
                        )),
                        e.integrationProvider) {
                            e.integrationProvider.match(/^[A-Za-z\s0-9.\-_]*$/i) || (console.error("[SpreadShop] Invalid integrationProvider '" + e.integrationProvider + "' found - will not use this! Valid values are strings/numbers (and whitespaces, ., -, _)."),
                            delete e.integrationProvider)
                        }
                    }(e);
                    var o = document.getElementById(e.baseId);
                    !function(e, o) {
                        if (!o) {
                            var t = "<h2>Could not embed your SpreadShop!</h2><p>Please add a container with the id '" + e.baseId + "' to your HTML file.</p>";
                            throw d(t),
                            new Error("Could not embed SpreadShop: Element with id '" + e.baseId + "' not found")
                        }
                    }(e, o),
                    function(e, o) {
                        if (null === document.doctype) {
                            console.error("[SpreadShop] Could embed shop '" + e.shopName + "' because of missing doctype. Please add '<!DOCTYPE html>' at the beginning of your html file.");
                            d('\n    <h2>Could not properly render your SpreadShop!</h2>\n    <p>This HTML Document does not specify a doctype. This can lead to very strange layout and styling errors.</p> \n    <p>Please add \'&lt;!DOCTYPE html&gt;\' at the beginning of your html file.</p>\n    <br />\n    <small>For more information read the documentation of <a href="https://www.w3schools.com/tags/tag_doctype.asp">doctype</a> and <a href="https://developer.mozilla.org/en-US/docs/Mozilla/Mozilla_quirks_mode_behavior">quirks mode</a>.</small>\n  ', o)
                        }
                    }(e, o);
                    var t, i, a = (t = e.prefix,
                    i = "/",
                    -1 !== t.indexOf(i, t.length - i.length) ? e.prefix.substring(0, e.prefix.length - 1) : e.prefix);
                    window.sprd_public_path = a + "/js/",
                    function(e) {
                        var o = document.createElement("script");
                        o.type = "text/javascript",
                        o.src = e + "/js/shop.bundle." + r + ".js",
                        o.onerror = function() {
                            d("Could not embed SpreadShop: Failed to load shop script")
                        }
                        ,
                        document.head.appendChild(o)
                    }(a),
                    function(e, o) {
                        Object(n.a)("sprd-css", e + "/shopfiles/css/shop_prefixed." + r + ".css"),
                        Object(n.a)("sprd-custom-css", e + "/" + o.shopName + "/shopData/custom.css?locale=" + o.locale + "&version=" + r + (o.loadFonts ? "&loadFonts=true" : ""))
                    }(a, e)
                }
                var a = !1
                  , s = window.spread_shop_config;
                s && "object" == typeof s && (document.getElementById(s.baseId) && (i(s),
                a = !0));
                a || document.addEventListener("DOMContentLoaded", (function() {
                    i(s)
                }
                ))
            }
        }

        
        function t(n) {
            if (o[n])
                return o[n].exports;
            var r = o[n] = {
                i: n,
                l: !1,
                exports: {}
            };
            return ppp[n].call(r.exports, r, r.exports, t),
            r.l = !0,
            r.exports
        }
        t.m = ppp,
        t.c = o,
        t.d = function(e, o, n) {
            t.o(e, o) || Object.defineProperty(e, o, {
                enumerable: !0,
                get: n
            })
        }
        ,
        t.r = function(e) {
            "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(e, Symbol.toStringTag, {
                value: "Module"
            }),
            Object.defineProperty(e, "__esModule", {
                value: !0
            })
        }
        ,
        t.t = function(e, o) {
            if (1 & o && (e = t(e)),
            8 & o)
                return e;
            if (4 & o && "object" == typeof e && e && e.__esModule)
                return e;
            var n = Object.create(null);
            if (t.r(n),
            Object.defineProperty(n, "default", {
                enumerable: !0,
                value: e
            }),
            2 & o && "string" != typeof e)
                for (var r in e)
                    t.d(n, r, function(o) {
                        return e[o]
                    }
                    .bind(null, r));
            return n
        }
        ,
        t.n = function(e) {
            var o = e && e.__esModule ? function() {
                return e.default
            }
            : function() {
                return e
            }
            ;
            return t.d(o, "a", o),
            o
        }
        ,
        t.o = function(e, o) {
            return Object.prototype.hasOwnProperty.call(e, o)
        }
        ,
        t.p = "/js/",
        t(t.s =307)
      }
    }  
  })(myExtObject||{})
  
  
  var webGlObject = (function() { 
    return { 
      init: function() { 
        alert('webGlObject initialized');
      } 
    } 
  })(webGlObject||{})